import React, { useCallback, useEffect, useState } from 'react'

import { graphql } from 'gatsby'

import { LinkCustom, Seo } from '../components'
import { BannerPage } from '../components/BannerPage'
import { Container } from '../components/Container'
import { Map } from '../components/Map'

interface ICountryAirtable {
  node: {
    data: {
      Code_of_country_longer: string
      Episode__from_Guests_: null | []
      Name_country: string
    }
  }
}

interface ICountriesData {
  id: string
  value: number
}

const WorldMap = ({ data }) => {
  const [episodesCountry, setEpisodesCountry] = useState<ICountriesData[]>([])
  const { countries } = data

  const transformCountries = useCallback((dataCountries: ICountryAirtable[]): ICountriesData[] => {
    const countriesToReturn: ICountriesData[] = []

    dataCountries.map((current) => {
      if (current.node.data.Episode__from_Guests_ === null) return

      countriesToReturn.push({
        id: current.node.data.Code_of_country_longer,
        value: current.node.data.Episode__from_Guests_?.length
          ? current.node.data.Episode__from_Guests_.length
          : 0,
      })
    })

    return countriesToReturn
  }, [])

  useEffect(() => {
    setEpisodesCountry(transformCountries(countries.edges))
  }, [countries, transformCountries])

  const title = 'World Map'
  const description = "The world is vast! Let's find out where our hosts are coming from!"

  return (
    <>
      <Seo title={title} description={description} pathname="world-map/" isLanding />
      <BannerPage title={title} description={description} />
      <Container className="pt-10">
        <div className="max-w-xl mx-auto mt-20 markdown">
          <p>
            This World Map shows where our hosts live and how many episodes were aired from people
            from a specific country.
          </p>
        </div>

        {episodesCountry.length > 0 && <Map data={episodesCountry} />}

        <p className="text-2xl text-center py-5">
          Join the community and share{' '}
          <LinkCustom to="/be-a-guest/" className="color-primary underline">
            your story with the world!
          </LinkCustom>
        </p>
      </Container>
    </>
  )
}

export default WorldMap

export const query = graphql`
  query EpisodesByCountryQuery {
    countries: allAirtable(filter: { table: { eq: "Country" } }) {
      edges {
        node {
          data {
            Episode__from_Guests_
            Name_country
            Code_of_country_longer
          }
        }
      }
    }
  }
`
